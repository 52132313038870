<!--
 * @Description: 车行道停车运营管理 运营管理 实时停车 realtimeParking
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <el-row class="topSelectLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input v-model="searchForm.plateNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场名称">
              <el-select v-model="searchForm.parkId"
                         placeholder="请选择">
                <el-option v-for="item in parkNameList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="泊位号">
              <el-input v-model="searchForm.parkSpaceNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="上报方式">
              <el-select v-model="searchForm.orderTypeCode"
                         placeholder="请选择">
                <el-option v-for="item in orderTypeCodeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="入场时间">
              <el-date-picker v-model="Time"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="topButton">
        <el-button type="primary"
                   @click="queryList">查 询</el-button>
        <el-button type="info"
                   @click="resetForm"
                   style="margin-left:17px;">重 置</el-button>
      </el-row>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>实时停车</span>
        <!-- <el-button type="info"
                   class="tableTitleButton">导出</el-button> -->
      </el-row>
      <el-row class="tableContent">
        <el-row class="tableContent-left">
          <el-image style="width:95%;height:660px;"
                    v-if="imgPic"
                    :src="$imgBaseUrl + imgPic"
                    :preview-src-list="previewsrcList" />
        </el-row>
        <el-row class="tableContent-right">
          <dt-table :tableColumnList="tableColumnList"
                    :data="tableList"
                    :map="listMap"
                    :tableFun="tableFun"
                    :tableConfig='tableConfig'
                    :paginationConfig='paginationConfig'
                    @getList="getList"
                    :tableHeight="600">
            <template slot="operating">
              <el-table-column label="操作"
                               class-name='class-name'>
                <template slot-scope="scope">
                  <el-col :span="12">
                    <el-button @click="handleClickWatch(scope.row)"
                               type="text"
                               size="medium"> 查看 </el-button>
                  </el-col>
                  <!-- <el-col :span="5">
                    <el-button @click="handleClickUpLock(scope.row)"
                               type="text"
                               size="medium"> 抬锁 </el-button>
                  </el-col>
                  <el-col :span="5">
                    <el-button @click="handleClickDownLock(scope.row)"
                               type="text"
                               size="medium"> 降锁 </el-button>
                  </el-col> -->
                  <el-col :span="12">
                    <el-button @click="handleSelection(scope.row)"
                               type="text"
                               size="medium">进场图片</el-button>
                  </el-col>
                </template>
              </el-table-column>
            </template>
          </dt-table>
        </el-row>
      </el-row>
    </el-row>
    <!-- 查看弹窗 -->
    <el-dialog title="实时停车详情"
               :visible.sync="dialogVisible"
               append-to-body>
      <el-row class="line">
        <el-col :span="12">
          <el-row class="lineTitle">订单编号 : {{lineItemRow.orderSequence}}</el-row>
          <el-row class="lineTitle">车牌号 : {{lineItemRow.plateNumber}}</el-row>
          <el-row class="lineTitle">停车场名称 : {{lineItemRow.parkName}}</el-row>
          <el-row class="lineTitle">车辆类型 : 小型车辆</el-row>
        </el-col>
        <el-col :span="12">
          <el-row class="lineTitle">上报方式 : {{lineItemRow.orderType}}</el-row>
          <el-row class="lineTitle">车牌颜色 : {{lineItemRow.plateColor}}</el-row>
          <el-row class="lineTitle">泊位号 : {{lineItemRow.parkSpaceNumber}}</el-row>
          <el-row class="lineTitle">入场时间 : {{lineItemRow.inTime}}</el-row>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
export default {
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      tableConfig: { border: true },
      tableFun: { 'row-click': this.handleSelection },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      listMap: { pageNum: 1, pageSize: 15, },
      tableList: { list: [] },
      tableColumnList: [
        {
          prop: 'orderSequence',
          label: '订单编号',
        },
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'parkName',
          label: '停车场名称',
        },
        {
          prop: 'parkSpaceNumber',
          label: '泊位号',
        },
        {
          prop: 'orderType',
          label: '上报方式',
        },
        {
          prop: 'inTime',
          label: '入场时间',
        },
      ],
      searchForm: {}, // 搜索内容
      pageNum: 1, // 分页
      pageSize: 15,
      imgPic: "",//左侧展示图片
      previewsrcList: [],//左侧图片预览
      orderTypeCodeList: [],//上报方式
      parkNameList: [],// 停车场名称
      Time: null,
      //查看弹窗
      dialogVisible: false,
      lineItemRow: {},
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      value1: '',
      value2: '',
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryParkNameList()
    this.queryOrderTypeCodeList()
    this.queryTableList()
  },
  //方法集合
  methods: {
    // 点击查看进场 图片
    handleSelection (row) {
      this.previewsrcList = []
      let info = {
        orderSequence: row.orderSequence
      }
      this.$queryDict.getEvidenceByOrderSequence(info).then(res => {
        this.imgPic = res.resultEntity[0].evidencePic
        this.previewsrcList.push(this.$imgBaseUrl + this.imgPic)
      })
    },
    // 获取实时停车列表
    queryTableList () {
      this.previewsrcList = []
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$realtimeParking.realTimeParking(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.content
        this.paginationConfig.total = res.resultEntity.totalElements
        if (this.tableList.list.length != 0) {
          let orderSequence = this.tableList.list[0].orderSequence
          let info = {
            orderSequence: orderSequence
          }
          this.$queryDict.getEvidenceByOrderSequence(info).then(response => {
            this.imgPic = response.resultEntity[0].evidencePic
            this.previewsrcList.push(this.$imgBaseUrl + this.imgPic)
          })
        }
        else {
          this.imgPic = ""
          this.previewsrcList = []
        }
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 查询
    queryList () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      if (this.Time) {
        this.searchForm.inStartTime = this.Time[0]
        this.searchForm.inEndTime = this.Time[1]
      } else {
        this.searchForm.inStartTime = ''
        this.searchForm.inEndTime = ''
      }
      this.queryTableList()
    },
    // 重置
    resetForm () {
      this.searchForm = {}
      this.Time = []
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    // 操作中的查看按钮
    handleClickWatch (row) {
      this.lineItemRow = row
      this.dialogVisible = true
    },
    // 抬锁
    handleClickUpLock (row) {
      let info = {
        parkId: row.parkId,
        parkSpaceId: row.parkSpaceNumber,
        pageNum: 1,
        pageSize: 1
      }
      this.$yardManagementApi.ParkSpaceController(info).then(res => {
        if (res.resultEntity.list[0].groundLock === "") {
          this.$message({ message: '该车位暂无地锁', type: 'info' });
        }
        else {
          let info = {
            deviceId: res.resultEntity.list[0].groundLock
          }
          let that = this
          that.$confirm('是否确定抬锁?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            that.$confirm('请再次确认是否抬锁?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              that.$floorLock.downLock(info).then(() => {
                that.$message({ message: '抬锁成功', type: 'success' })
              })
            }).catch(() => {
              that.$message({ message: '抬锁已取消', type: 'info' })
            })
          }).catch(() => {
            that.$message({ message: '抬锁已取消', type: 'info' })
          })
        }
      })
    },
    // 降锁
    handleClickDownLock (row) {
      let info = {
        parkId: row.parkId,
        parkSpaceId: row.parkSpaceNumber,
        pageNum: 1,
        pageSize: 1
      }
      this.$yardManagementApi.ParkSpaceController(info).then(res => {
        if (res.resultEntity.list[0].groundLock === "") {
          this.$message({ message: '该车位暂无地锁', type: 'info' });
        }
        else {
          let info = {
            deviceId: res.resultEntity.list[0].groundLock
          }
          let that = this
          that.$confirm('是否确定降锁?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            that.$confirm('请再次确认是否降锁?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              that.$floorLock.upLock(info).then(() => {
                that.$message({ message: '降锁成功', type: 'success' })
              })
            }).catch(() => {
              that.$message({ message: '降锁已取消', type: 'info' })
            })
          }).catch(() => {
            that.$message({ message: '降锁已取消', type: 'info' })
          })
        }
      })
    },
    // 上报方式下拉
    queryOrderTypeCodeList () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'BD131BB123B1H2JMBM41NB231K2J11F',
          },
        ],
      }
      this.$queryDict.queryDict(info).then((res) => {
        this.orderTypeCodeList = res.resultEntity
      })
    },
    // 停车场名称下拉
    queryParkNameList () {
      let info = {
        "columnName": ["park_id", "park_name"],
        "tableName": "tb_park",
        "whereStr": [
          {
            "colName": "park_type_code",
            "value": 1
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        this.parkNameList = res.resultEntity
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 185px;
.mainBody {
  height: 100%;
  .topSelectLine {
    @include background('blockBg');
    width: 100%;
    height: $topSelectHeight;
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    @include background('blockBg');
    width: 100%;
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      width: 100%;
      height: 100%;
      display: flex;
      margin-top: 10px;
      .tableContent-left {
        width: 30%;
        margin-bottom: 20px;
      }
      .tableContent-right {
        width: 70%;
      }
    }
  }
}
.lineTitle {
  margin-top: 10px;
  color: #79baf7;
  font-size: 16px;
}
</style>
